<template>
    <div class="ash" :class="{ noAsh: problem.data[page] && problem.data[page].no_select && problem.data[page].id!==-2 , last:page==problem.data.length-1 }">
        <vue-header title="问诊" :fixed="true" />
        <div v-for="( item, index ) in problem.data" v-bind:key="index" class="selects">
            <div v-if="index<=page">
                <div class="title flex">
                    <div class="portrait">
                        <img src="../../../../images/robot.png"/>
                    </div>
                    <div class="problem">{{item.title}}</div>
                </div>
                <div v-if="sift(item,index).show">
                    <div v-if="item.no_select">
                        <div v-if="item.id==-2" class="birth">
                            <div class="describe">请准确填写您的出生时间可以有助于判断您的先天健康状态</div>
                            <div class="describe-h">
                                <van-datetime-picker
                                    v-model="currentDate"
                                    type="datetime"
                                    :formatter="formatter"
                                    :show-toolbar="false"
                                    :min-date="minDate"
                                    :max-date="maxDate"
                                    item-height='40'
                                    visible-item-count='3'
                                    @change="dateChange"
                                />
                            </div>
                            <div class="describe-s">
                                <van-datetime-picker
                                    v-model="currentDate"
                                    type="datetime"
                                    :formatter="formatter"
                                    :show-toolbar="false"
                                    :min-date="minDate"
                                    :max-date="maxDate"
                                    item-height='50'
                                    visible-item-count='5'
                                    @change="dateChange"
                                />
                            </div>
                            <div class="submit flex flex-x">
                                <button @click="DateFun">确定</button>
                            </div>
                        </div>
                        <div v-else-if="item.id==-3 || item.id==-4" class="stature-bottom">
                            <div class="stature">
                                <div class="latle">请输入您的{{item.id==-3?'身高':'体重'}}</div>
                                <div class="stature-top flex flex-x">
                                    <div class="stature-input flex">
                                        <div class="flex-1">
                                            <span v-if="item.content[0]">{{item.content[0]}}</span>
                                            <span v-else class="hui">请输入{{item.id==-3?'身高':'体重'}}</span>
                                        </div>
                                        <div class="suffix">{{item.id==-3?'cm':'kg'}}</div>
                                    </div>
                                    <div class="stature-share" @click="()=>problemFun(false,item.id,index)">
                                        <van-icon name="share" />
                                    </div>
                                </div>
                            </div>
                            <div class="ask-clavier">
                                <van-number-keyboard :show="true"
                                                :extra-key="'清空'"
                                                @input="(value)=>problemChange({
                                                    key:index,
                                                    value:value
                                                })"
                                                @delete="()=>problemChange({
                                                    key:index,
                                                    value:-1
                                                })"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="item.id==-1" class="gender">
                            <div class="gender-name">请选择您的性别</div>
                            <div class="flex">
                                <div v-for="( cont, index1 ) in item.content" v-bind:key="index1" class="flex flex-x flex-1">
                                    <div class="select" :class="{ active: cont.active }"   @click="()=>problemFun({
                                        key:index,
                                        index:index1,
                                        value:true
                                    })">
                                        <div class="select-img">
                                            <img src="../../../../images/boy.png" v-if="cont.label=='男'"/>
                                            <img src="../../../../images/girl.png" v-else/>
                                        </div>
                                        <div>{{cont.label}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="content-box">
                            <div v-for="( cont, index1 ) in item.content" v-bind:key="index1" class="content flex flex-y" :class="{ active: cont.active }" @click="problemChange({
                                key:index,
                                index:index1,
                                value:true
                            })">
                                <div class="checkbox flex flex-x flex-y">
                                    <van-icon name="success" />
                                </div>
                                <div>{{item.id>0?`${['A','B','C','D','E','F','G','H','I','J','K'][index1]}. `:''}}{{screenFun(cont.label)}}</div>
                            </div>
                            <div class="topic-next flex flex-x">
                                <button v-if="page+1!==problem.data.length" @click="()=>problemFun(false,item.id)">下一题</button>
                                <button v-else @click="()=>submit(item.id)" >提交</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="answer flex">
                        <div class="flex-1"></div>
                        <div class="problem problem-right">
                            <span v-for="( value, index1 ) in sift(item,index).value" v-bind:key="index1">
                                {{screenFun(value)}}
                            </span>
                        </div>
                        <div class="portrait">
                            <img src="../../../../images/user.png"/>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="flex-1"></div>
                        <div class="modify" @click="()=>modifyFun(index)">点击修改</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loading flex flex-x flex-y" v-if="loading">
            <div class="loading-box">
            <div class="loading-icon">
                <van-loading />
                <div class="loading-ui">提交中</div>
            </div>
            </div>
        </div>
        <div class="topic-next flex flex-x" v-if="problem.data.length!==0 && page==problem.data.length && !loading">
            <button @click="()=>submit()">重新提交</button>
        </div>
        <div v-if="show">
            <vue-popup url="report" :showFun="showFun" :token="token" :callback="callbackFun" />
        </div>
    </div>
</template>

<script>
import vueHeader from '@/components/header';
import { mapState , mapMutations , mapActions } from "vuex";
import vuePopup from "@/components/popup";

export default {
  name: 'bianz',
  components:{
    vueHeader,
    vuePopup
  },
  data(){
    return{
        prev:-1,
        maxDate:new Date(),
        minDate:new Date(1900, 0, 1),
        currentDate:new Date(1970, 0, 1),
        date:['1970','01','01','00','00'],
    }
  },
  computed: {
    ...mapState ("question", ["problem","page","loading","height","weight","token","show"]),
  },
  created: function () {
    this.questions();    //获取问题
  },
  destroyed: function () {
      this.closeFun();
  },
  methods: {
        showFun(){
            this.change({
                key:'show',
                value:false
            })
        },
        callbackFun(){
            this.$router.push({
                name:'report',
                params: {
                    id:this.token
                }
            })
        },
      sift(item,index){
        let bool = {
            show:true,
            value:[]
        };
        if(item.no_select){
            let select_bool = true;
            for(let i=0,n=item.content.length;i<n;i++){
                if(!item.content[i]){
                    select_bool = false;
                }
            }
            if(select_bool){
                if(item.id==-2){
                    bool.show = false;
                    bool.value.push(`${item.content[0]}-${item.content[1]}-${item.content[2]} ${item.content[3]}:${item.content[4]}`);
                }else if(item.id==-3){
                    bool.show = this.$store.state.question.height;
                    bool.value.push(`${item.content[0]}cm`);
                }else if(item.id==-4){
                    bool.show = this.$store.state.question.weight;
                    bool.value.push(`${item.content[0]}kg`);
                }
            }
        }else{
            let rooy = false;
            for(let i=0,n=item.content.length;i<n;i++){
                if(item.content[i].active){
                    rooy = true;
                    bool.value.push(item.content[i].label);
                }
            }
            if(rooy && this.page>index){
                bool.show = false;
            }else{
                bool.show = true;
            }
        }
        return bool;
      },
      //乳房胀痛
      screenFun(label){
        let data = this.$store.state.question.problem.data[0];
        let sex = 2;
        if(data.id==-1 && data.content[0].active){
            sex = 1;
        }
        if(sex==1 && label=='乳房胀痛'){
            return '胸胀胸痛';
        }else{
            return label;
        }
      },
      //最后的提交
      submit(id){
          if(id){
              this.problemFun(false,id);
          }
          this.submitData({_this:this});
      },
      //存一下用户的时间
      dateChange(picker){
          let startTime = picker.getValues();
          let year=parseInt(startTime[0]),month=parseInt(startTime[1]),day=parseInt(startTime[2]),hour=parseInt(startTime[3]),minute=parseInt(startTime[4]);
          month = month>9?month:`0${month}`;
          day = day>9?day:`0${day}`;
          hour = hour>9?day:`0${hour}`;
          minute = minute>9?day:`0${minute}`;
          let date = [`${year}`,`${month}`,`${day}`,`${hour}`,`${minute}`];
          this.date = date;
      },
      //提交出生日期
      DateFun(){
        let problem = this.problem.data;
        for(let i=0,n=problem.length;i<n;i++){
            if(problem[i].id==-2){
                problem[i].content = this.date
                break;
            }
        }
        if(this.prev>0){
            this.pageChange({
                value:this.prev
            });
            this.prev = -1;
        }else{
            this.pageChange({
            value:this.page+1
            });
        }

        setTimeout(function(){
              //滚动条到最底部
            window.scrollTo(0,document.body.scrollHeight);
        },1)
      },
      //时间格式
      formatter(type, val) {
        if (type === 'year') {
            return val + '年';
        }
        if (type === 'month') {
            return val + '月';
        }
        if (type === 'day') {
            return val + '日';
        }
        if (type === 'hour') {
            return val + '时';
        }
        if (type === 'minute') {
            return val + '分';
        }
        return val;
      },
      problemFun(obj,id,index){
          if(obj){
              this.problemChange(obj);
          }
          if(index){
              let problem = this.problem.data;
              if(problem[index].content[0]){
                if(problem[index].range[0]<=problem[index].content[0] && problem[index].content[0]<=problem[index].range[1]){
                    if(id==-3){
                        this.change({
                            key:'height',
                            value:false
                        })
                    }else if(id==-4){
                        this.change({
                            key:'weight',
                            value:false
                        })
                    }
                }
              }
          }
          if(this.prev>0){
                this.pageChange({
                    value:this.prev
                });
                this.prev = -1;
          }else{
              this.pageChange({
                value:this.page+1
              });
          }
          setTimeout(function(){
              //滚动条到最底部
            window.scrollTo(0,document.body.scrollHeight);
          },1)
      },
      modifyFun(index){
          this.prev = this.page;
          let problem = this.problem.data;
          if(problem[index].no_select){
              if(problem[index].id==-2){
                  problem[index].content = ['','','','00','00'];
              }else if(problem[index].id==-3){
                  problem[index].content = [''];
                  this.change({
                        key:'height',
                        value:true
                  });
              }else if(problem[index].id==-4){
                  problem[index].content = [''];
                  this.change({
                        key:'weight',
                        value:true
                  });
              }
          }else{
            for(let i=0,n=problem[index].content.length;i<n;i++){
                problem[index].content[i].active = false
            }
          }
          this.pageChange({
            value:index
          });
          setTimeout(function(){
              //滚动条到最底部
            window.scrollTo(0,document.body.scrollHeight);
          },1)
      },
      ...mapMutations ("question", ["pageChange","problemChange","closeFun","change","questions"]),
      ...mapActions ("question", ["submitData"]),
  }
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'skin';
</style>